<template>
  <v-app  translate="no" class="notranslate">


    <v-overlay :value="isLoading">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :value="cvalue"
        color="#ee6923"
      >
        {{ cvalue }}
      </v-progress-circular>
      <br />
    </v-overlay>

    <!-- <v-main style="z-index:500"> -->
    <v-main translate="no" class="notranslate">
      <div class="app_bg" style="position: fixed;z-index: 0;top:0;height:100vh; left: 0;right: 0;"></div>
      <router-view  @pushe="pushe">

        
      </router-view>



      

      <div id="header" style="     position: fixed;    top: 0;        min-width: 375px;    max-width: 500px;    width: 100%; background-color: white;        padding-bottom: 10px; padding-top: 10px;" v-if="!['Login'].includes(this.$route.name)">
      <v-row>
        <v-col  cols="3" style="display: flex; justify-content: start;      flex-direction: row;    align-items: center;    align-self: center;" class="pl-7">
        <!-- left icos -->
          <v-img src="img/profico.png" @click="$router.push({ name: 'Profile' })"  v-if="['Main'].includes(this.$route.name)"  style="max-width: 38px;"></v-img>
          <v-img v-if="!['Main','TPQ'].includes(this.$route.name)" src="img/ico_exit.png" @click="$router.push({ name: 'Main' })"  style="max-width: 38px;"></v-img>
        
        </v-col>
        <v-col class="header_global"  @click="$router.push({ name: 'Main' })" cols="6" style="display: flex;    flex-direction: column;    align-items: center;    align-self: center;padding-bottom: 5px;">
          <!-- header_txt -->
          <div v-if="['Profile'].includes(this.$route.name)">Профиль</div>
          <div v-if="['News_arch'].includes(this.$route.name)">Сповіщення</div>
          <div v-if="['TPQ'].includes(this.$route.name)">Комунікація</div>
           <div v-if="['Main'].includes(this.$route.name)">iTeam</div>
           <div v-if="['News_pg'].includes(this.$route.name)">Планограми</div>
           <div v-if="['C_info'].includes(this.$route.name)">Про компанію</div>
           
           <div v-if="['Phototask'].includes(this.$route.name)">Фото вітрини</div>
           
           <div v-if="['Phototask_dmx'].includes(this.$route.name)">Мій товарний запас</div>
           <div v-if="['Testtask'].includes(this.$route.name)">Завдання</div>
           <div v-if="['Balance'].includes(this.$route.name)">Мої бали</div>
           <div v-if="['News'].includes(this.$route.name)">Новини</div>
           <div v-if="['Withdraw_sel','Withdraw_silpo','Withdraw_wog','Withdraw_rozetka','Withdraw_novus','Withdraw_eva','Withdraw_epi','Withdraw_atb'].includes(this.$route.name)">Сертифікати</div>
          

           <div v-if="['Info_Brands_Davidoff','Info_Brands_Davidoff_SL','Info_Brands_Davidoff_reach','Info_Brands_Davidoff_PL','Info_Brands_Davidoff_40'].includes(this.$route.name)">iTeam</div>

          <div v-if="['Info_Brands_West','Info_Brands_West_XL','Info_Brands_West_UP','Info_Brands_West_KS'].includes(this.$route.name)">iTeam</div>

          <div v-if="['Info_Brands_Jade','Info_Brands_Jade_SSL'].includes(this.$route.name)">iTeam</div>

          
          <div v-if="['Info_Brands_ImperialClassic','Info_Brands_IC_Compact','Info_Brands_IC_25'].includes(this.$route.name)" style="font-size: 20px;">iTeam</div>

          <div v-if="['Info_Brands_Prima','Info_Brands_Prima_sribna','Info_Brands_Prima_lux'].includes(this.$route.name)">iTeam</div>
          <div v-if="['LPR_STAT'].includes(this.$route.name)">Статистика</div>

        </v-col>
        <v-col  cols="3" style="    justify-content: end;display: flex;    flex-direction: row;    align-items: center;    align-self: center;" class="pr-7">
         <!-- right_icos -->
         <!-- <v-img src="img/mailico.png" @click="$router.push({ name: 'News_arch' })"  v-if="['Main'].includes(this.$route.name)" style="max-width: 38px;margin-right: 10px;" ></v-img> -->
         <v-img src="img/mailico.png" @click="$router.push({ name: 'News_arch' })"  v-if="['Main'].includes(this.$route.name)" style="max-width: 38px;margin-right: 10px;" ></v-img>
          <v-img src="img/sapico.png" @click="$router.push({ name: 'TPQ' })"  v-if="!['TPQ'].includes(this.$route.name)" style="max-width: 38px;"></v-img>
        </v-col>

      </v-row>
    </div>


    <div id="footer" style="    position: fixed;    bottom: 0;        min-width: 375px;    max-width: 500px;    width: 100%;   background-color: white;    padding-bottom: 10px;" 
    v-if="!['Login'].includes(this.$route.name)">
      <v-row>
        <v-col  @click="$router.push({ name: 'Balance' })" cols="4" style="display: flex;    flex-direction: column;    align-items: center;    align-self: center;"><center><v-img src="img/footer_ico_balance.png" style="max-width: 48px;"></v-img></center></v-col>
        <v-col  @click="$router.push({ name: 'Main' })" cols="4" style="display: flex;    flex-direction: column;    align-items: center;    align-self: center;padding-bottom: 5px;"><center><v-img src="img/footer_home.png" style="max-width: 65px;"></v-img></center></v-col>
        <v-col  @click="$router.push({ name: 'Withdraw_sel' })" cols="4" style="display: flex;    flex-direction: column;    align-items: center;    align-self: center;"><center><v-img src="img/footer_ico_cert.png"  style="max-width: 48px;"></v-img></center></v-col>

      </v-row>
    </div>
    </v-main>


    <v-dialog
        v-model="push_dialog"
        width="300" style="z-index:55000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">{{push_header}}</v-card-title>
          <v-card-text>
             <v-row> <v-col v-html="push_text">
             
                </v-col>
             </v-row>
           
            
          </v-card-text>
          <v-card-actions>
            
            <v-btn
              color="primary"
              text
              @click="push_dialog = false"
            >
              Закрити
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>







<script>
import axios from "axios";

import { API_LOCATION } from "../config";
export default {
  name: "App",

  components: {},
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    },
    
  },

  data: () => ({
    //iconTeam: require("./assets/LogoITG.svg"),
    drawer: false,

    push_dialog:false,
    push_header:'',
    push_text:'',
    uploadPercentage: 0,
    cvalue: 0,
    refCount: 0,
    opDesc: [],
    isLoading: false,
    //
  }),

watch:{
  $route (){
      this.push_check();
  }
} ,


  created() {



   // this.intervalid1 = setInterval(this.push_check, 60000);






    console.log(API_LOCATION);


    // axios
    //   .post(
    //     API_LOCATION,
    //     {
          
    //       action: "version_check"
          
    //     },
    //     { headers: { "content-type": "application/x-www-form-urlencoded" } }
    //   )
    //   .then((resp) => {
    //           console.log(resp.data);  
    //           var version=190
    //           if(resp.data!==version)
    //           {
    //             location.reload();  
    //           }
        
    //   });


    console.log(this.$store);
    axios.interceptors.request.use(
      (config) => {
        this.setLoading(true);

        return config;
      },
      (error) => {
        this.setLoading(false);
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
          if (
            error.status === 401 &&
            error.config &&
            !error.config.__isRetryRequest
          ) {
            this.$store.dispatch("logout");
          }
          console.log(error);
          return error;
        });
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.setLoading(false);
        return response;
      },
      (error) => {
        this.setLoading(false);
        //eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => { 
          console.log(error);
          if (error.response.status === 401) {
            this.$store.dispatch("logout").then(() => {
              this.$router.push("/login");
            });
          }
        });
      }
    );
    axios.interceptors.response.use(undefined, function (err) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout");
        }
        console.log(err.status);
        throw err;
      });
    });

    this.$store.dispatch("page_title_set", "main");
    if (this.isLoggedIn) {
      this.$store.dispatch("profileset")
      .catch((err) => console.log(err));
    }
  },

  

    methods: {

    pushe(data){
      this.push_dialog=true;
      this.push_header=data.header;
      this.push_text=data.text;
      console.log('push emit:'+data);
    },


    push_check(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "push"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        console.log(resp.data);
              if(resp.data!=false)         {
                this.pushe({header:resp.data.header,text:resp.data.message});              }
        
      });
    },


      go_back(){
          //if(this.$route.name=='Phototask'||this.$route.name=='Testtask'){this.$router.push({ name: 'Main' })} else { this.$router.go(-1) this.$router.}
           this.$router.go(-1)
      },

    setLoading(isLoading) {
      if (isLoading) {
        this.refCount++;
        this.isLoading = true;
      } else if (this.refCount > 0) {
        this.refCount--;

        this.isLoading = this.refCount > 0;
      }
      this.cvalue = Math.round(100 / this.refCount);
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  
    
    
}

</script>
<style>
.cardstyle1{
  background-color:#fdaa47;color:white;text-align:center; padding:6px !important;
}


.cardstyle2{
  background-color:#ffffff;color:white;text-align:center; padding:6px !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-width: 3px;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top-width: 3px;
  border-top-color: black;
  border-top-style: solid;
}

.card{
   position:fixed !important;top:90px !important;left:0 !important;right:0 !important; margin:auto !important;z-index:1000 !important;width:80% !important;
}
.card-ny{
  position:fixed !important;top:90px !important;left:0 !important;right:0 !important; margin:auto !important;z-index:1000 !important;width:80% !important;border-radius: 18px !important;
}

</style>